<script>
  import page from "page";
  import { slide } from "svelte/transition";
  import AutoComplete from "simple-svelte-autocomplete";
  import { request } from "../tools/request";
  import WarehouseItemInfo from "./WarehouseItemInfo.svelte";
  import Button from "./Button.svelte";
  import LimitCardPrint from "./LimitCardPrint.svelte";
  import WarehouseCardItem from "./WarehouseCardItem.svelte";
  import WarehouseLimitCardErrors from "./WarehouseLimitCardErrors.svelte";

  export let cardId;

  const defaultCard = {
    id: cardId === "add" ? null : cardId,
    isFinalized: false,
    isInProgress: false,
    data: {
      title: "Новая лимитно-заборная карта",
      items: [],
      total: 0,
      totalPrice: 0,
      sizes: [
        { id: "one size", value: null, price: 0, totalPrice: 0 },
        { id: "2xs", value: null, price: 0, totalPrice: 0 },
        { id: "xs", value: null, price: 0, totalPrice: 0 },
        { id: "s", value: null, price: 0, totalPrice: 0 },
        { id: "m", value: null, price: 0, totalPrice: 0 },
        { id: "l", value: null, price: 0, totalPrice: 0 },
        { id: "xl", value: null, price: 0, totalPrice: 0 },
        { id: "2xl", value: null, price: 0, totalPrice: 0 },
        { id: "3xl", value: null, price: 0, totalPrice: 0 },
        { id: "4xl", value: null, price: 0, totalPrice: 0 },
        { id: "5xl", value: null, price: 0, totalPrice: 0 },
      ],
    },
  };

  let errors = {};
  let submitting = false;
  let card = { ...defaultCard };
  let copiedOk = false;

  if (card.id) {
    getCard();
  }

  $: locked = card.isFinalized;
  $: {
    card.data.sizes.forEach((size) => {
      size.price = 0;
      size.totalPrice = 0;
    });
    card.data.total = card.data.sizes.reduce((a, b) => a + b.value || 0, 0);
    card.data.items.forEach((item) => {
      item.thisPrice = 0;
      card.data.sizes.forEach((size) => {
        const sizeValue = getValueFromSizes(size, item);
        if (sizeValue) {
          item.sizes[size.id] = item.sizes[size.id] || {
            label: size.id,
            cut: 0,
            quantityPerPiece: 0,
            thisQuantity: 0,
            footage: 0,
          };
          item.sizes[size.id].thisQuantity =
            (item.sizes[size.id]?.quantityPerPiece || 0) * sizeValue;
          item.sizes[size.id].footage =
            ((item.sizes[size.id]?.cut || 0) *
              (item.sizes[size.id]?.quantityPerPiece || 0) *
              sizeValue) /
            100;
          if (item.unit === "piece") {
            item.sizes[size.id].price =
              item.sizes[size.id].thisQuantity * item.price || 0;
          } else {
            item.sizes[size.id].price =
              item.sizes[size.id].footage * item.price || 0;
          }
          item.thisPrice = item.thisPrice + item.sizes[size.id].price;
        }
      });
    });
    card.data.totalPrice = 0;
    card.data.sizes.forEach((size) => {
      size.price = getSizePrice(size.id);
      size.totalPrice = size.price * size.value;
      card.data.totalPrice = card.data.totalPrice + size.totalPrice;
    });
  }

  function r(val) {
    return Math.round(val * 100) / 100;
  }

  function getSizePrice(sizeId) {
    let price = 0;
    card.data.items.forEach((item) => {
      const itemSizeId = item.hasSizes ? sizeId : "one size";
      if (!item.sizes[itemSizeId]) {
        return;
      }
      if (item.unit === "piece") {
        price += item.price * item.sizes[itemSizeId].quantityPerPiece;
      } else {
        price +=
          (item.price *
            item.sizes[itemSizeId].cut *
            item.sizes[itemSizeId].quantityPerPiece) /
          100;
      }
    });
    return price || 0;
  }

  function getValueFromSizes(size, item) {
    // когда позиции не нужны размеры, нужно считать все размеры сумарно.
    // например молнии нужны по одной штуке на каждый товар каждого размера.
    if (size.id === "one size" && !item.hasSizes) {
      return card.data.sizes.reduce((a, b) => a + b.value || 0, 0);
    }
    return size.value;
  }

  // $: if (!card.id && card.data.items.length) {
  //   validate();
  // }

  function copy() {
    const items = [...card.data.items];
    const sizes = [...card.data.sizes];
    card = { ...defaultCard, ...{ id: null } };
    setTimeout(() => {
      card.data.items = [...items];
      card.data.sizes = [...sizes];
      page("/warehouse/cards/add");
    }, 1000);
  }

  async function getCard() {
    const { ok, data } = await request({
      path: `cards/${card.id}`,
    });

    if (ok) {
      card = { ...data };
    }
  }

  async function getItems(keyword) {
    if (!keyword) {
      return [];
    }

    let results = [];
    let page = null;
    while (true) {
      const { ok, data } = await request({
        path: "items",
        params: { search: keyword, page },
      });
      if (!ok) {
        break;
      }

      results = [...results, ...data.results];
      if (!data.next) {
        break;
      }
      page = new URL(data.next).searchParams.get("page");
    }

    return results;
  }

  function addItem(item) {
    if (!item) {
      return;
    }
    item = {
      ...item,
      ...{
        comment: "",
        hasSizes: false,
        sizes: {},
      },
    };
    card.data.items = [...card.data.items, item];
  }

  function removeItem(index) {
    card.data.items = [
      ...card.data.items.slice(0, index),
      ...card.data.items.slice(index + 1, card.data.items.length),
    ];
  }

  async function validate() {
    await request({
      method: "POST",
      path: "cards/validate",
      json: {
        data: card.data,
      },
    });
  }

  async function save(isFinalized) {
    if (card.data.id) {
      return;
    }

    if (isFinalized) {
      if (!confirm("Завершить карту? Будут затронуты данные на складе.")) {
        return;
      }
    }
    submitting = true;
    const postData = { ...card };
    errors = {};
    postData.isFinalized = !!isFinalized;
    postData.data.items.forEach((item) => {
      postData.data.sizes.forEach((size) => {
        if (
          !(item.sizes[size.id]?.cut || item.sizes[size.id]?.quantityPerPiece)
        ) {
          delete item.sizes[size.id];
        }
      });
    });

    const path = card.id ? `cards/${card.id}` : "cards";
    const { ok, data, error } = await request({
      method: "POST",
      path,
      json: {
        ...postData,
      },
    });
    submitting = false;
    if (ok) {
      card = { ...data };
      page(`/warehouse/cards/${data.id}`);
    } else {
      errors = { ...error };
      setTimeout(() => {
        errors = {};
      }, 10000);
    }
  }

  function deleteCard(returnToWarehouse) {
    return async () => {
      const text = returnToWarehouse
        ? "Удалить карту?"
        : "Удалить карту без возврата на склад?";
      if (!card.id || !confirm(text)) {
        return;
      }

      const { ok } = await request({
        method: "DELETE",
        path: `cards/${card.id}`,
        params: {
          returnToWarehouse,
        },
      });
      if (ok) {
        location.pathname = "/warehouse/cards";
      }
    };
  }

  function disableSizeInput(size) {
    if (size.id === "one size") {
      for (const s of card.data.sizes) {
        if (s.id === "one size") {
          continue;
        }
        if (s.value) {
          return true;
        }
      }
    } else {
      for (const s of card.data.sizes) {
        if (s.id === "one size") {
          return Boolean(s.value);
        }
      }
    }
  }

  const setIsInProgress = async (e) => {
    if (!card.id) {
      return;
    }
    const { ok, error } = await request({
      path: `cards/${card.id}`,
      json: {
        id: card.id,
        isInProgress: e.target.checked,
      },
    });

    if (!ok) {
      alert(error.isInProgress);
    }
  };
</script>

<div
  class="flex h-full w-full min-w-[1200px] flex-col justify-between gap-1 pt-1 print:hidden border-slate-200 text-xs"
>
  <div class="basis-full">
    <div class="h-full w-full bg-white py-5">
      <div class="flex flex-col gap-2.5">
        <div class="flex w-full">
          <div class="flex h-12 w-full justify-between">
            <div
              class="flex flex-col px-3 text-xl font-bold w-full justify-center"
            >
              <div class="flex flex-col gap-2">
                <div class="flex gap-2 items-center">
                  <a href="/warehouse/cards" class="print:hidden flex h-full"
                    ><span class="material-symbols-outlined"
                      >arrow_back_ios_new</span
                    ></a
                  >
                  {#if card.isFinalized}
                    <span
                      >{card.data.title} от {new Date(
                        card.createdAt,
                      ).toLocaleString()}</span
                    >
                  {:else}
                    <input
                      class="basis-1/4"
                      type="text"
                      bind:value={card.data.title}
                    />
                  {/if}
                </div>
                <div class="flex gap-2 pl-8 items-center text-base font-normal">
                  <input
                    id="isInProgres"
                    type="checkbox"
                    bind:checked={card.isInProgress}
                    on:change={setIsInProgress}
                  />
                  <label class="cursor-pointer" for="isInProgres"
                    >Проверка</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-24 pb-3">
            <div class="col-span-7 border-l-1 py-3 px-3.5">
              Наименование фурнитуры
            </div>
            <div class="col-span-5 border-l-1 py-3 px-3.5">
              Назначение фурнитуры
            </div>
            <div class="col-span-1 border-l-1 py-3 text-center">Размер</div>
            <div class="col-span-2 border-l-1 py-3 px-3.5">Нарезка</div>
            <div class="col-span-2 border-l-1 py-3 px-3.5">Метраж</div>
            <div class="col-span-2 border-l-1 py-3 px-3.5">Кол-во/ед.</div>
            <div class="col-span-2 border-l-1 py-3 px-3.5">Кол-во</div>
            <div class="col-span-2 border-l-1 border-r-1 py-3 px-3.5">Цена</div>
            <div>&nbsp;</div>
          </div>
          {#each card.data.items as item, index}
            <div transition:slide>
              <WarehouseCardItem {card} bind:item>
                <div
                  role="presentation"
                  slot="deleteButton"
                  class=" h-full w-full border-r-1"
                  on:keydown
                  on:click={() => (locked ? null : removeItem(index))}
                >
                  {#if !locked}
                    <div
                      class="flex h-full w-full items-center justify-center text-slate-400"
                    >
                      <i class="material-symbols-outlined text-xl">clear</i>
                    </div>
                  {/if}
                </div>
              </WarehouseCardItem>
            </div>
          {/each}
          <div class="h-12 w-full">
            <AutoComplete
              hideArrow
              searchFunction={getItems}
              delay="200"
              labelFieldName="title"
              localFiltering={false}
              onChange={addItem}
              disabled={locked}
              minCharactersToSearch="0"
              placeholder="Добавить позицию"
            >
              <div slot="item" let:item>
                <WarehouseItemInfo {item}>
                  <span slot="category" class="text-black">
                    {item.category.title}
                  </span>
                </WarehouseItemInfo>
              </div>
            </AutoComplete>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex gap-1">
    <div class="basis-7/12">
      <div class="h-full w-full bg-white py-5">
        <div class="px-5 flex h-full flex-col justify-between gap-3">
          <div class="text-xl font-bold">Размеры</div>
          <div
            class="grid grid-cols-11 place-items-center text-sm font-bold uppercase"
          >
            {#each card.data.sizes as size}
              <div>{size.id}</div>
            {/each}
          </div>
          <div class="grid grid-cols-11 place-items-center text-sm uppercase">
            {#each card.data.sizes as size, index}
              <input
                type="number"
                class="w-full text-center disabled:text-slate-400"
                disabled={locked || disableSizeInput(size)}
                on:click={(e) => {
                  e.target.focus();
                  e.target.select();
                }}
                bind:value={card.data.sizes[index].value}
                placeholder="---"
              />
            {/each}
          </div>
          <div class="grid grid-cols-10 place-items-center text-xs">
            {#each card.data.sizes as size}
              <div
                class="flex flex-col items-center {!size.value
                  ? 'opacity-0'
                  : ''}"
              >
                <div>
                  <span>{r(size.price)}</span><span class="text-slate-400"
                    >&nbsp;р</span
                  >
                </div>
                <div>
                  <span>{r(size.totalPrice)}</span><span class="text-slate-400"
                    >&nbsp;р</span
                  >
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
    <div class="basis-5/12">
      <div class="h-full w-full bg-white py-5">
        <div class="flex h-full flex-col justify-between gap-2.5 px-3">
          <div class="text-xl font-bold">
            Итого: {card.data.total}&nbsp;шт.&nbsp;{r(
              card.data.totalPrice,
            )}&nbsp;руб.
          </div>
          <div class="h-10">
            {#if locked}
              <div class="h-full w-full flex gap-2.5">
                <div class="h-full basis-1/2">
                  <Button danger on:click={deleteCard(1)}>Удалить</Button>
                </div>
                <div class="h-full basis-1/2">
                  <Button danger on:click={deleteCard("")}
                    >Удалить без возврата на склад
                  </Button>
                </div>
              </div>
            {:else}
              <div class="h-full w-full flex gap-2.5">
                <div class="h-full basis-1/2">
                  <Button
                    accent
                    disabled={submitting}
                    on:click={() => {
                      save();
                    }}
                    >Сохранить
                  </Button>
                </div>
                <div class="h-full basis-1/2">
                  <Button
                    accent
                    disabled={submitting}
                    on:click={() => {
                      save(true);
                    }}
                    >Сохранить и Завершить
                  </Button>
                </div>
              </div>
            {/if}
          </div>
          <div class="h-10">
            <div class="h-full w-full flex gap-2.5">
              <div class="flex gap-1 h-full basis-1/2">
                <div class="flex-grow">
                  <Button
                    secondary
                    on:click={() => {
                      window.print();
                    }}
                    >Печать
                  </Button>
                </div>
                <div class="flex-shrink">
                  <Button
                    secondary
                    on:click={() => {
                      const html =
                        document.getElementById("card-print").outerHTML;

                      const clipboardItem = new ClipboardItem({
                        "text/html": new Blob([html], { type: "text/html" }),
                        "text/plain": new Blob([html], { type: "text/plain" }),
                      });

                      navigator.clipboard
                        .write([clipboardItem])
                        .then(() => {
                          copiedOk = true;
                          setTimeout(() => {
                            copiedOk = false;
                          }, 1000);
                        })
                        .catch((error) => alert(error));
                    }}
                  >
                    {#if copiedOk}
                      <span class="material-symbols-outlined">check</span>
                    {:else}
                      <span class="material-symbols-outlined">content_copy</span
                      >
                    {/if}
                  </Button>
                </div>
              </div>
              <div class="h-full basis-1/2">
                <Button secondary on:click={copy}>Копировать</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<WarehouseLimitCardErrors bind:errors />
<LimitCardPrint bind:card />
