<script>
  import { request, refreshCSRF, getBaseUrl } from "../lib/tools/request";
  import ReadOnlyTransfer from "../lib/components/ReadOnlyTransfer.svelte";
  import { onMount } from "svelte";
  import TransferTitle from "../lib/components/TransferTitle.svelte";
  import SelectTransferStorage from "../lib/components/SelectTransferStorage.svelte";
  import EditTransfer from "../lib/components/EditTransfer.svelte";
  import { sizeLabels } from "../lib/stores/sizeLabels.js";

  export let params;
  let transfer = {};
  let saving = false;
  let errors = {};
  let importErrors = {};
  let transferItems = [];
  let totalTransfers = 0;
  $: {
    let t = 0;
    sizeLabels.forEach((s) =>
      Object.values(transferItems).forEach((i) => (t += Number(i[s]) || 0)),
    );
    totalTransfers = t;
  }

  const fetchTransfer = async () => {
    const { ok, data } = await request({
      path: `storage-transfers/${params.id}`,
    });

    if (ok) {
      transfer = data;
    }
  };

  const save = async () => {
    errors = {};
    saving = true;
    const { ok, error } = await request({
      path: `storage-transfers/${transfer.id}`,
      json: {
        id: transfer.id,
        storageFrom: transfer.storageFrom,
        storageTo: transfer.storageTo,
        comment: transfer.comment,
      },
    });

    if (!ok) {
      errors = error;
    }

    saving = false;
  };

  const send = async () => {
    await save();
    saving = true;
    const { error } = await request({
      path: `storage-transfers/${transfer.id}/send`,
      method: "POST",
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  const cancel = async () => {
    saving = true;
    const { error } = await request({
      path: `storage-transfers/${transfer.id}/cancel`,
      method: "POST",
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  const receive = async () => {
    saving = true;
    const { error } = await request({
      path: `storage-transfers/${transfer.id}/receive`,
      method: "POST",
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  const importFromTemplate = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (
      transferItems.length &&
      !confirm("Импорт заменит все добавленные наименования")
    ) {
      return;
    }

    const fd = new FormData();
    fd.append("file", file);
    fd.append("action", "updateTransfer");
    fd.append("transferId", transfer.id);

    const { data: csrfToken } = await refreshCSRF();
    const url = new URL("import-sku/", getBaseUrl());
    const response = await fetch(url, {
      method: "POST",
      body: fd,
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken.csrf,
      },
    });

    if (!response.ok) {
      importErrors = await response.json();
    } else {
      location.reload();
    }
  };

  const changeStatus = (status) => async () => {
    saving = true;

    const { error } = await request({
      path: `storage-transfers/${transfer.id}`,
      json: {
        id: transfer.id,
        status,
      },
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  onMount(fetchTransfer);
</script>

<div class="w-full bg-white">
  <div class="w-full flex flex-col gap-3 py-2">
    <TransferTitle {transfer} />
    <SelectTransferStorage
      bind:storageFrom={transfer.storageFrom}
      bind:storageTo={transfer.storageTo}
      status={transfer.status}
      transferId={transfer.id}
    >
      <svelte:fragment>
        {#if ["created", "canceled"].includes(transfer.status)}
          <button
            disabled={saving}
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5 disabled:bg-gray-600 whitespace-nowrap"
            on:click={save}
            >Сохранить (+{totalTransfers})
          </button>
          <button
            disabled={saving}
            class="disabled:bg-gray-600 disabled:text-white bg-white hover:bg-slate-100 border border-slate-200 rounded-xl text-black px-4 py-1.5"
            on:click={send}
            >В резерв
          </button>
        {:else if ["reserved", "ready_to_ship", "shipped"].includes(transfer.status)}
          <button
            disabled={saving}
            class="bg-gray-400 hover:bg-gray-500 rounded-xl text-white px-4 py-1.5"
            on:click={cancel}
            >Отменить
          </button>
        {/if}
        {#if transfer.status === "reserved"}
          <button
            disabled={saving}
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5"
            on:click={changeStatus("ready_to_ship")}
            >Приготовить к отгрузке
          </button>
        {:else if transfer.status === "ready_to_ship"}
          <button
            disabled={saving}
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5"
            on:click={changeStatus("shipped")}
            >Отгрузить
          </button>
        {:else if transfer.status === "shipped"}
          <button
            disabled={saving}
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5"
            on:click={receive}
            >Завершить
          </button>
        {/if}
        {#if ["created", "canceled"].includes(transfer.status)}
          <input
            type="file"
            class="h-[0.1px] w-[0.1px]"
            on:change={importFromTemplate}
            id="importFromTemplate"
          />
          <label
            for="importFromTemplate"
            class="disabled:bg-gray-600 disabled:text-white bg-white hover:bg-slate-100 border border-slate-200 rounded-xl text-black px-4 py-1.5"
          >
            Импорт из файла-шаблона
          </label>

          <a
            href="https://storage.yandexcloud.net/ya.igandesigner.ru/extra-urls/External-Source-Template.xlsx"
            target="_blank"
            class="text-blue-400 px-4 py-1.5"
            >Скачать файл шаблона
          </a>
        {/if}

        <div class="flex gap-1 text-red-400 text-sm items-center">
          {#each errors?.nonFieldErrors || [] as error}
            <span>{error}</span>
          {/each}
        </div>
      </svelte:fragment>
    </SelectTransferStorage>
    {#if ["created", "canceled"].includes(transfer.status)}
      <EditTransfer
        bind:storageFrom={transfer.storageFrom}
        bind:errors
        bind:transferItems
        bind:transfer
      />
    {:else if ["reserved", "ready_to_ship", "shipped", "done"].includes(transfer.status)}
      <ReadOnlyTransfer {transfer} />
    {/if}
    {#if importErrors}
      <div class="flex flex-col gap-1 text-sm text-red-400 px-4">
        {#each Object.entries(importErrors) as k}
          <span>{k[0]}: {k[1]}</span>
        {/each}
      </div>
    {/if}
  </div>
</div>
