<script lang="ts">
  import { onMount } from "svelte";
  import { getBaseUrl, refreshCSRF, request } from "../lib/tools/request";

  let storages = [];
  let loading = false;
  const actions = [
    {
      key: "createTransfer",
      title: "Создать перемещение",
    },
    {
      key: "createRevision",
      title: "Создать инвентаризацию",
    },
    {
      key: "updateStorage",
      title: "Обновить количество на складе",
    },
  ];

  let storage = {};
  let action = {};
  let importFile = null;
  let errors = null;
  let ok = true;
  let result = {};

  $: formIsValid = action?.key && storage?.id && importFile;

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = data.results;
    }
  };

  const sendForm = async () => {
    if (!formIsValid) {
      return;
    }

    if (
      action.key === "updateStorage" &&
      !confirm("Импорт заменит все значения на складе. Хотите продолжить?")
    ) {
      return;
    }

    const fd = new FormData();
    fd.append("file", importFile);
    fd.append("action", action.key);
    fd.append("storage", storage.id);

    const { data: csrfToken } = await refreshCSRF();
    const url = new URL("import-sku/", getBaseUrl());
    errors = null;
    ok = true;
    result = {};
    const response = await fetch(url, {
      method: "POST",
      body: fd,
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken.csrf,
      },
    });

    ok = response.ok;
    if (ok) {
      result = await response.json();
    } else {
      errors = await response.json();
    }
  };

  const getActionResultURL = (objectId) => {
    if (action.key === "createTransfer") {
      return `/transfers/${objectId}`;
    }
    if (action.key === "createRevision") {
      return `/revisions/${objectId}`;
    }
    if (action.key === "updateStorage") {
      return `/storage/${objectId}`;
    }
  };

  onMount(fetchStorages);
</script>

<div class="w-full h-full flex flex-col gap-5 p-5">
  <h1 class="text-xl font-bold">Импорт</h1>
  <div class="flex flex-col gap-5 w-fit min-w-[300xp]">
    <div class="w-full flex flex-col gap-1">
      <span class="text-sm pr-2 font-bold">Склад</span>
      <div class="w-full flex items-center gap-1 py-2 flex-wrap">
        {#each storages as s}
          <button
            on:click={() => (storage = s)}
            class:bg-blue-400={s.id === storage?.id}
            class:text-white={s.id === storage?.id}
            class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
          >
            {s.title}
          </button>
        {/each}
      </div>
    </div>

    <div class="w-full flex flex-col gap-1">
      <span class="text-sm pr-2 font-bold">Действие</span>
      <div class="w-full flex items-center gap-1 py-2 flex-wrap">
        {#each actions as a}
          <button
            on:click={() => (action = a)}
            class:bg-blue-400={action?.key === a.key}
            class:text-white={action?.key === a.key}
            class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
          >
            {a.title}
          </button>
        {/each}
      </div>
    </div>

    <div class="w-full flex flex-col gap-1">
      <span class="text-sm pr-2 font-bold">Файл</span>
      <div class="w-full flex items-center gap-1 py-2 flex-wrap">
        <input
          on:change={(e) => (importFile = e.target?.files?.[0])}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
    </div>

    <div class="w-full flex flex-col gap-1">
      <div class="w-full flex items-center gap-1 py-2 flex-wrap">
        <div class="flex gap-1">
          <button
            on:click={sendForm}
            disabled={loading || !formIsValid}
            class="disabled:bg-gray-200 disabled:text-gray-400 w-32 flex items-center justify-center h-11 border rounded-xl bg-blue-400 text-white cursor-pointer transition-colors"
          >
            {#if loading}
              <span class="material-symbols-outlined animate-spin"
                >progress_activity</span
              >
            {:else}Загрузить{/if}
          </button>
          <a
            href="https://storage.yandexcloud.net/ya.igandesigner.ru/extra-urls/External-Source-Template.xlsx"
            target="_blank"
            class="text-blue-400 px-4 py-1.5"
            >Скачать файл шаблона
          </a>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col gap-1">
      <div class="w-full flex flex-col gap-1 py-2 flex-wrap">
        {#if result?.objectId}
          <span class="text-lg font-bold">{result.msg}</span>
          <a
            target="_blank"
            href={getActionResultURL(result.objectId)}
            class="text-blue-400"
            >Перейти к объекту
          </a>
        {/if}
      </div>
    </div>
    <div class="w-full flex flex-col gap-1">
      <div class="w-full flex items-center gap-1 py-2 flex-wrap">
        {#if errors}
          <div class="w-full">
            <pre>{JSON.stringify(errors, null, 1)}</pre>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
